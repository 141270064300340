<template>
  <div class="dashboard-container" v-loading="loading">
    <el-row :gutter="20" style="margin-bottom: 0">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="clearfix echart-title">
            <p>活动分类数据</p>
            <chart-timer-picker active-tag="month1" :tags="['month1', 'month3', 'year1']" @time-change="handleTimeChange"/>
          </div>
          <div class="echart-alltitle">
            <en-table-layout :toolbar="false" :tableData="tableData.data" :loading="loading" @sort-change="reSort">
              <!-- 表格数据 -->
              <template slot="table-columns">
                <el-table-column prop="category_name" label="活动分类名称" />
                <el-table-column prop="goods_num" label="活动数量" sortable="custom"/>
                <el-table-column prop="shop_num" label="商户数量" sortable="custom"/>
                <el-table-column prop="pv_num" label="浏览量（pv)" sortable="custom"/>
                <el-table-column prop="uv_num" label="浏览人数（uv）" sortable="custom"/>
                <el-table-column prop="per_capita_time" label="人均浏览详情页时长/min" sortable="custom"/>
                <el-table-column prop="final_conversion" label="最终转化率" sortable="custom"/>
                <el-table-column prop="refund_rate" label="退款率" sortable="custom"/>
              </template>
              <!-- 表格分页 -->
              <el-pagination
                v-if="tableData"
                slot="pagination"
                @size-change="handlePageSizeChange"
                @current-change="handlePageCurrentChange"
                :current-page="tableData.page_no"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="tableData.page_size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.data_total"
              ></el-pagination>
            </en-table-layout>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as API_Sta from "@/api/$sta.trade.js";
import ChartTimerPicker from '../chart-timer-picker';

export default {
  name: "dashboard",
  components: { ChartTimerPicker },
  data() {
    return {
      loading: false,
      goodsList: null,
      memberList: null,
      /** 基本数据 */
      info: {},
      advancedForm1: {
        times: "",
      },
      params: {
        page_no: 1,
        page_size: 10
      },
      // 订单统计
      active: "day",
      activeName: "今日",
      // 退款订单统计
      active2: "day",
      activeName2: "今日",
      advancedForm2: {
        times: "",
      },
      order: '',
      shop: '',
      tradeData: {},
      tableData: {},
      // 活动分类
      typeList: [],
      category_first: '',
      category_second: '',
      category_id: 0,
      priceMilli0: '',
      priceMilli1: '',
    };
  },
  filters: {
    secrecyMobile(mobile) {
      mobile = String(mobile);
      if (!/\d{11}/.test(mobile)) {
        return mobile;
      }
      return mobile.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
    },
  },
  created() {
    // this.GET_List();
  },
  activated() {},
  mounted() {},
  methods: {
    reSort(obj) {
      if (obj.order) {
        this.params.order_name = obj.prop;
        this.params.order_type = obj.order.replace(/ending/g, '');
      } else {
        delete this.params.order_name;
        delete this.params.order_type;
      }
      this.GET_List()
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },
    handleTimeChange ({ times, milli, activeName}) {
      this.params.start_time = milli[0];
      this.params.end_time = milli[1];
      this.GET_List();
    },
    GET_List() {
      this.loading = true;
      API_Sta.getCatData(this.params).then((res) => {
        this.tableData = res;
        this.loading = false;
      })
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;
  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  img {
    margin-top: 22px;
  }
  div {
    span {
      display: block;
    }
    span:nth-child(1) {
      color: #212121;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #434343;
    }
  }
  img {
    width: 82px;
    height: 82px;
  }
}
.echart-title::after {
  display: none;
}
.echart-title {
  height: 50px;
  font-size: 18px;
  color: #007f7f;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p:nth-child(2) {
    color: #212121;
    font-size: 14px;
  }
  p span {
    cursor: pointer;
    line-height: 20px;
    height: 24px;
    display: inline-table;
    margin: 0 5px;
  }

  p span.active {
    color: #007f7f;
    border-bottom: 2px solid #007f7f;
  }
}

.echart-alltitle {
  display: flex;
  justify-content: space-around;
}
.echarts-left1 {
  width: 15%;
}
.echarts-left50 {
  width: 50%;
  padding: 10px;
  ::v-deep .layout-container{
    height: auto;
    background-color: #fff;
  }
}
.echarts-left {
  height: 100px;
  width: 100%;
  background: rgba(51, 205, 189, 0.08);
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 0px;
    color: #212121;
  }
  p {
    font-size: 15px;
    line-height: 0px;
    font-weight: 500;
    margin-top: -10px;
    color: #656565;
  }
}
.echarts-left:nth-child(2),
.echarts-left:nth-child(3) {
  background: rgba(253, 85, 87, 0.08);
}
.echarts-right {
  width: 80%;
  height: 350px;
}

.elcard {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    background: #f3f5f7;
    border-radius: 8px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    padding: 0 5px;
    cursor: pointer;
  }
}

.dashboard-container {
  height: 100%;
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .goods-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    a {
      display: block;
      margin: 0;
      width: 75%;
      button {
        width: 100%;
      }
    }
  }
  .goods-image {
    width: 50px;
    height: 50px;
  }
}
.el-row {
  position: relative;
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
</style>
